<script setup lang="ts">
import { computed, ref } from "vue"
import {
  BaseInput,
  FieldsetLegend,
  MultiCheckboxes,
  debounceFn,
  useModel,
} from "@xy-planning-network/trees"
import { recordToMultiselectOptions } from "@/composables/forms"
import { SearchFindAnAdvisorProfileParams } from "@/types/profile"
import Accordion from "@/components/shared/Accordion/Accordion.vue"

const props = defineProps<{
  niches: Record<string, string[]>
  params: SearchFindAnAdvisorProfileParams
}>()

defineEmits<{
  (e: "update:params", v: SearchFindAnAdvisorProfileParams): void
}>()

const searchParams = useModel(props, "params")

// Lazyily update the search input.
const searchFilter = ref("")
const lazySearchFilter = debounceFn((inputVal: string) => {
  searchFilter.value = inputVal.trim()
}, 350)

/**
 * nicheOptions computes the available niche groups and options
 * to display.  When the search filter is not in use all options are available
 * and their panel states are mapped to the panelStates ref which tracks user history
 * of the panel state.
 *
 * When the search filter is in use, unmatched niches are removed and empty groups are hidden.
 * Any remaining groups will automatically have their panel set to an open state.
 */
const nicheOptions = computed(() => {
  let niches = props.niches

  if (searchFilter.value) {
    niches = Object.entries(props.niches).reduce(
      (filtered, [cat, niches]) => {
        const nichesMatches = niches.filter((n) =>
          n.toLowerCase().includes(searchFilter.value.toLowerCase())
        )

        if (nichesMatches.length > 0) {
          filtered[cat] = nichesMatches
        }

        return filtered
      },
      {} as Record<string, string[]>
    )
  }

  return recordToMultiselectOptions(niches).map((nicheGroup) => {
    const groupActive = nicheGroup.options.reduce((active, option) => {
      if (searchParams.value.niches?.includes(option.value)) {
        active = true
      }
      return active
    }, false)

    return {
      ...nicheGroup,
      open: searchFilter.value ? true : groupActive,
    }
  })
})
</script>

<template>
  <div class="pb-6">
    <div class="border-b border-gray-100 pb-5 mb-2.5">
      <BaseInput
        :model-value="searchFilter"
        type="text"
        label="Search Advisor Specialties"
        placeholder="Filter all specialties by keyword"
        @update:model-value="lazySearchFilter"
      />
    </div>

    <div
      v-if="nicheOptions.length === 0"
      class="font-semibold text-sm text-center"
    >
      No results. Adjust your filter for more options.
    </div>

    <!--
    NOTE(spk): by setting a key that includes the searchFilter value, 
    we can ensure a render cycle that will reset the panels open state.
    This may or may not be desireable, let user feedback determine what to do.
    -->
    <div
      v-for="group in nicheOptions"
      :key="`${group.label}-${searchFilter}`"
      class="py-3"
    >
      <Accordion :open="group.open" layout="seamless">
        <template #toggle>
          <FieldsetLegend :label="group.label" :for="group.label" />
        </template>

        <template #panel>
          <div class="p-3">
            <MultiCheckboxes
              :id="`#${group.label}`"
              v-model="searchParams.niches"
              :options="group.options"
            />
          </div>
        </template>
      </Accordion>
    </div>
  </div>
</template>
